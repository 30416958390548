import React, { FC } from "react";
import styled from "styled-components";

import { theme } from "components/theme";

type Props = {
  data: { id: number; idToNavigate: string; section: JSX.Element }[];
  activeSection: number;
};

const ScrollContainer = styled.div`
  position: fixed;
`;

const ScrollBarElement = styled.div<{ isVisible?: boolean }>`
  display: block;
  background: ${({ isVisible }) =>
    isVisible ? theme.colors.gray["500"] : theme.colors.gray["100"]};
  width: 4px;
  height: 136px;
  margin-bottom: 32px;
  cursor: pointer;
  transition: 0.2s ease;
  &:hover {
    width: 6px;
    background: ${theme.colors.gray["300"]};
  }
`;

export const ScrollBySection: FC<Props> = ({ data, activeSection }) => {
  const handleClick = (idToNavigate: string) => {
    document.querySelector(`#${idToNavigate}`)?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <ScrollContainer>
      {data.map((datum) => (
        <ScrollBarElement
          onClick={() => handleClick(datum.idToNavigate)}
          key={datum.id}
          isVisible={datum.id === activeSection}
        />
      ))}
    </ScrollContainer>
  );
};
