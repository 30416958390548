import styled from "styled-components";

interface TextProps {
  align?: string;
  fontSize?: string;
  bold?: boolean;
  color?: string;
  mobilefontsize?: string;
  mb?: number;
  mbmobile?: number;
  mt?: number;
  mr?: number;
  ml?: number;
  fontFamily?: "Post Grotesk" | "Post Grotesk Thin" | "Post Grotesk Bold";
}

export const Text = styled.p<TextProps>`
  color: ${({ color }) => color ?? "#000"};
  font-family: ${({ fontFamily }) => fontFamily ?? "Letter Gothic std"};
  font-weight: ${({ bold }) => (bold ? "700" : "400")};
  font-size: ${({ fontSize }) => fontSize ?? 16}px;
  margin-bottom: ${({ mb }) => mb ?? 0}px;
  margin-top: ${({ mt }) => mt ?? 0}px;
  margin-right: ${({ mr }) => mr ?? 0}px;
  margin-left: ${({ ml }) => ml ?? 0}px;
  text-align: ${({ align }) => (align === "center" ? "center" : "left")};
  white-space: pre-wrap;

  @media (max-width: ${({ theme }) => theme.device.portraitTablet}px) {
    margin-bottom: ${({ mbmobile }) => mbmobile ?? 0}px;
    font-size: ${({ mobilefontsize }) => mobilefontsize ?? 16}px;
  }
`;
