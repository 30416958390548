import React, { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { ImageWithPreview } from "components/molecules/imageWithPreview";
import { SectionHeader } from "components/molecules/sectionHeader";
import { EXPERIENCES_DATA } from "src/utils/manifesto";

const ExperiencesSectionContainer = styled.section`
  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    padding: 0 60px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    padding: 0 40px;
  }
`;

const ExperienceGrid = styled.div`
  display: flex;
  column-gap: 40px;
  flex-wrap: wrap;
`;

export const ExperiencesSection: FC = () => {
  const { t } = useTranslation();

  return (
    <ExperiencesSectionContainer>
      <SectionHeader
        title={t("manifesto.sectionHeaders.experiences.title")}
        description={t("manifesto.sectionHeaders.experiences.description")}
      />
      <ExperienceGrid>
        {EXPERIENCES_DATA.map((experience, i) => (
          <ImageWithPreview
            key={i}
            title={experience.title}
            image={experience.image}
            isPreview={false}
          />
        ))}
      </ExperienceGrid>
    </ExperiencesSectionContainer>
  );
};
