// PILARS IMAGES
import Fashion from "assets/images/manifesto/fashion.jpg";
import Music from "assets/images/manifesto/music.jpg";
import Lifestyle from "assets/images/manifesto/lifestyle.jpg";
import Art from "assets/images/manifesto/art.jpg";
import Sport from "assets/images/manifesto/sport.jpg";

// TEAM IMAGES
import Julien from "assets/images/squadBW/julien.jpg";
import Ilyass from "assets/images/squadBW/ilyass.jpg";
import Jonathan from "assets/images/squadBW/jonathan.jpg";
import Aurelia from "assets/images/squadBW/aurelia.jpg";
import Marion from "assets/images/squadBW/marion.jpg";
import JulienHache from "assets/images/squadBW/julien-hache.jpg";
import Sebastien from "assets/images/squadBW/sebastien.jpg";
import Enzo from "assets/images/squadBW/enzo.jpg";
import JK from "assets/images/squadBW/jk.jpg";
import Jean from "assets/images/squadBW/jean.jpg";
import Adrien from "assets/images/squadBW/adrien.jpg";
import Joel from "assets/images/squadBW/joel.jpg";
import Manuel from "assets/images/squadBW/manuel.jpg";
import John from "assets/images/squadBW/john.jpg";

export const EXPERIENCES_DATA = [
  {
    title: "Fashion",
    image: Fashion,
    link: "https://whitepaper.societhy.com/societhy-the-first-experiential-metaverse/experience-themes/fashion",
  },
  {
    title: "Music",
    image: Music,
    link: "https://whitepaper.societhy.com/societhy-the-first-experiential-metaverse/experience-themes/music",
  },
  {
    title: "Lifestyle",
    image: Lifestyle,
    link: "https://whitepaper.societhy.com/societhy-the-first-experiential-metaverse/experience-themes/lifestyle",
  },
  {
    title: "Art",
    image: Art,
    link: "https://whitepaper.societhy.com/societhy-the-first-experiential-metaverse/experience-themes/art",
  },
  {
    title: "Sport",
    image: Sport,
    link: "https://whitepaper.societhy.com/societhy-the-first-experiential-metaverse/experience-themes/sport",
  },
];

export const SQUAD_MEMBERS_DATA = [
  {
    name: "Julien Keller",
    position: "Co-founder & CEO",
    image: Julien,
    linkedin: "https://www.linkedin.com/in/julien-keller/",
    twitter: "https://twitter.com/jul1keller",
  },
  {
    name: "Ilyass Ramdani",
    position: "Co-founder & CTO",
    image: Ilyass,
    linkedin: "https://www.linkedin.com/in/ilyass-ramdani/",
    twitter: "https://twitter.com/IlyassRamdani",
  },
  {
    name: "Jonathan Segers",
    position: "Community Manager",
    image: Jonathan,
    linkedin: "https://www.linkedin.com/in/jonathansegers/",
    twitter: "https://twitter.com/thisissmooth",
  },
  {
    name: "Aurelia Grandmaire",
    position: "Artistic Director",
    image: Aurelia,
    linkedin: "https://www.linkedin.com/in/aurélia-grandmaire-toniolo-/",
  },
  {
    name: "Enzo Hespel",
    position: "Full Stack Developer",
    image: Enzo,
    linkedin: "https://www.linkedin.com/in/enzo-hespel/",
    twitter: "https://twitter.com/Enzo_Hspl",
  },
  {
    name: "Jan-Kasper Jakubowski",
    position: "Full Stack Developer",
    image: JK,
    linkedin: "https://www.linkedin.com/in/jankasperjakubowski/",
    twitter: "https://twitter.com/jkjkb6",
  },
  {
    name: "Marion Theurkauff",
    position: "Fashion Designer",
    image: Marion,
    instagram: "https://www.instagram.com/pas2keur/",
  },
  {
    name: "Sebastien Mattia",
    position: "Unreal Engineer",
    image: Sebastien,
    linkedin: "https://www.linkedin.com/in/sebastien-mattia/",
  },
  // {
  //   name: "Julien Hache",
  //   position: "Blockchain Developper",
  //   image: JulienHache,
  //   linkedin: "https://www.linkedin.com/in/julien-hache-3564b6163/",
  //   twitter: "https://twitter.com/thebiptomybop",
  // },
  {
    name: "Joel Palix",
    position: "Advisor & Founder of Palix Unlimited",
    image: Joel,
    linkedin: "https://www.linkedin.com/in/joelpalix/",
  },

  {
    name: "Adrien Seugé",
    position: "Advisor & CMO of L’OREAL France",
    image: Adrien,
    linkedin: "https://www.linkedin.com/in/adrien-seugé-91867b2/",
  },
  {
    name: "Jean Keller",
    position: "Advisor & VP at Isabel Marant",
    image: Jean,
    linkedin: "https://www.linkedin.com/in/jean-keller-4bb16420/",
  },
  {
    name: "Manuel Valente",
    position: "Advisor & Scientific Director at Coinhouse",
    image: Manuel,
    linkedin: "https://www.linkedin.com/in/manuel-valente-994a15140/",
  },
  {
    name: "John Karp",
    position: "Advisor & CEO of NFT Factory",
    image: John,
    linkedin: "https://www.linkedin.com/in/jokarp/",
    twitter: "https://twitter.com/johnkarp",
  },
];
