import React, { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Text } from "components/atoms/text";
import { Button } from "components/atoms/button";
import { Icon } from "components/atoms/icon";

import { ReactComponent as Instagram } from "assets/icons/socials/instagram.svg";
import { ReactComponent as Twitter } from "assets/icons/socials/twitter.svg";
import { ReactComponent as Linkedin } from "assets/icons/socials/linkedin.svg";

type Props = {
  image?: string;
  title?: string;
  description?: string;
  isPreview?: boolean;
  isTeamMember?: boolean;
  link?: string;
  linkedin?: string;
  twitter?: string;
  instagram?: string;
};

const Element = styled.div<Props>`
  position: relative;
  min-height: ${({ isTeamMember }) => (isTeamMember ? "auto" : "337px")};
  max-width: ${({ isTeamMember }) => (isTeamMember ? "160px" : "240px")};

  a {
    display: none;
    @media (max-width: ${({ theme }) => theme.device.mobile}px) {
      display: block;
    }
  }

  p {
    display: block;
    font-size: 14px;
    @media (max-width: ${({ theme }) => theme.device.mobile}px) {
      position: absolute;
      top: 10px;
      left: 10px;
      color: #fff;
    }
  }

  &:hover {
    a {
      display: block;
    }
    p {
      display: ${({ isTeamMember }) => (!isTeamMember ? "none" : "inherit")};
    }
  }

  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    justify-self: center;
  }
`;

type ImageWrapperProps = {
  isPreview?: boolean;
};

const ImageWrapper = styled.div<Pick<Props, "isTeamMember" | "isPreview">>`
  position: relative;
  max-height: ${({ isTeamMember }) => (isTeamMember ? "160px" : "268px")};
  max-width: ${({ isTeamMember }) => (isTeamMember ? "160px" : "240px")};
  margin-bottom: 16px;

  &:hover {
    > div {
      display: flex;
    }

    p {
      display: ${({ isPreview }) => (isPreview ? "none" : "inherit")};
      @media (max-width: ${({ theme }) => theme.device.mobile}px) {
        display: block;
      }
    }
  }
`;

const ElementHovered = styled.div`
  display: none;
  align-items: center;
  position: absolute;
  border-radius: 2px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000ba;
`;

const SocialNetworkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 40px;

  & > a {
    display: block;
  }
  & > a:not(:last-child) {
    margin-right: 20px;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
  font-family: "Post Grotesk";
`;

const Image = styled.img<Pick<Props, "isTeamMember">>`
  border-radius: 2px;
  height: ${({ isTeamMember }) => (isTeamMember ? "160px" : "268px")};
  ${({ isTeamMember }) => (isTeamMember ? "width:160px;" : "max-width:240px")};
  object-fit: cover;
`;

export const ImageWithPreview: FC<Props> = ({
  image,
  title,
  description,
  isPreview,
  isTeamMember,
  link,
  twitter,
  linkedin,
  instagram,
}) => {
  const { t } = useTranslation();

  return (
    <Element isPreview={isPreview} isTeamMember={isTeamMember}>
      <ImageWrapper isPreview={isPreview} isTeamMember={isTeamMember}>
        <Image src={image} alt="" isTeamMember={isTeamMember} />
        {isTeamMember && (
          <ElementHovered>
            <SocialNetworkContainer>
              {twitter && (
                <Icon
                  href={twitter}
                  icon={<Twitter stroke="var(--white)" strokeWidth="1" />}
                />
              )}
              {linkedin && (
                <Icon
                  href={linkedin}
                  icon={<Linkedin stroke="var(--white)" strokeWidth="1" />}
                />
              )}
              {instagram && (
                <Icon
                  href={instagram}
                  icon={<Instagram stroke="var(--white)" strokeWidth="1" />}
                />
              )}
            </SocialNetworkContainer>
          </ElementHovered>
        )}
      </ImageWrapper>
      <Text fontFamily="Post Grotesk Thin">
        <BoldText>{title}</BoldText>
      </Text>
      <Text fontFamily="Post Grotesk Thin" mt={4}>
        {description && `${description}`}
      </Text>
      {isPreview && (
        <Button fullWidth type="secondary" href={link}>
          {t("home.tinies.previewButton")}
        </Button>
      )}
    </Element>
  );
};
