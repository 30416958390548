import React, { FC, useState } from "react";
import styled from "styled-components";
export { Head } from "src/utils/head";

import { useWindowSize } from "src/hooks/useWindowDimensions";
import { Layout } from "components/template/layout";
import { Button } from "components/atoms/button";
import { Text } from "components/atoms/text";

import { SociethySection } from "./_societhySection";
import { ExperiencesSection } from "./_experiencesSection";
import { CommunitySection } from "./_communitySection";
import { TeamSection } from "./_teamSection";
import { SectionContainer } from "./_sectionContainer";
import { ScrollBySection } from "./_scrollBySection";

type Props = {
  sticky?: boolean;
  experienceIsVisible?: boolean;
};

const DATA = [
  {
    id: 0,
    idToNavigate: "societhy",
    section: <SociethySection />,
  },
  {
    id: 1,
    idToNavigate: "experiences",
    section: <ExperiencesSection />,
  },
  {
    id: 2,
    idToNavigate: "community",
    section: <CommunitySection />,
  },
  {
    id: 3,
    idToNavigate: "team",
    section: <TeamSection />,
  },
];

const ManifestoContainer = styled.div`
  display: flex;
  margin-top: 140px;
  height: 100%;
`;

const ManifestoChild = styled.div`
  padding-right: 80px;
`;

const StickyManifestoChild = styled(ManifestoChild)`
  align-self: flex-start;
  height: 100%;
  padding: 0 80px 0 80px;
`;

const Center = styled.div`
  height: calc(100vh - 140px);
  width: 100vw;
  padding: 0 60px;
  justify-content: center;
  align-items: center;
`;

const ManifestoPage: FC<Props> = () => {
  const [activeSection, setActiveSection] = useState(0);
  const { width } = useWindowSize();

  return (
    <Layout>
      {width < 1200 ? (
        <ManifestoContainer>
          <Center>
            <Text mb={40} mbmobile={40}>
              For more informations on Societhy, you can check our white paper
            </Text>
            <Button href="https://whitepaper.societhy.com">
              Access to the white paper
            </Button>
          </Center>
        </ManifestoContainer>
      ) : (
        <ManifestoContainer>
          <StickyManifestoChild>
            <ScrollBySection data={DATA} activeSection={activeSection} />
          </StickyManifestoChild>

          <ManifestoChild>
            {DATA.map((datum) => (
              <SectionContainer
                key={datum.id}
                id={datum.id}
                idToNavigate={datum.idToNavigate}
                setActiveSection={setActiveSection}
              >
                {datum.section}
              </SectionContainer>
            ))}
          </ManifestoChild>
        </ManifestoContainer>
      )}
    </Layout>
  );
};

export default ManifestoPage;
