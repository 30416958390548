import React, { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { SectionHeader } from "components/molecules/sectionHeader";
import { ImageWithPreview } from "components/molecules/imageWithPreview";
import { SQUAD_MEMBERS_DATA } from "src/utils/manifesto";

const TeamSectionContainer = styled.section`
  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    padding: 0 60px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    padding: 0 40px;
  }
`;

const TeamGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
`;

export const TeamSection: FC = () => {
  const { t } = useTranslation();

  return (
    <TeamSectionContainer>
      <SectionHeader title={t("manifesto.sectionHeaders.team.title")} />
      <TeamGrid>
        {SQUAD_MEMBERS_DATA.map((member, i) => (
          <ImageWithPreview
            key={i}
            title={member.name}
            description={member.position}
            image={member.image}
            linkedin={member.linkedin}
            instagram={member.instagram}
            twitter={member.twitter}
            isTeamMember
          />
        ))}
      </TeamGrid>
    </TeamSectionContainer>
  );
};
