import React, { FC } from "react";
import styled from "styled-components";

import { Text } from "components/atoms/text";
import { Title } from "components/atoms/title";

type Props = {
  title: string;
  description?: string;
};

const HeaderWrapper = styled.div`
  margin-bottom: 80px;
  width: 60%;
`;

export const SectionHeader: FC<Props> = ({ title, description }) => {
  return (
    <HeaderWrapper>
      <Title type="h2" mb={40} fontSize="120">
        {title}
      </Title>
      <Text
        fontSize="16"
        mbmobile={60}
        fontFamily="Post Grotesk Thin"
        style={{ letterSpacing: "3.2px" }}
      >
        {description}
      </Text>
    </HeaderWrapper>
  );
};
