import React, { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { SectionHeader } from "components/molecules/sectionHeader";
import SociethyBanner from "assets/images/hub/manifesto_banner.png";

const SociethySectionContainer = styled.section`
  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    padding: 0 60px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    padding: 0 40px;
  }
`;

const Image = styled.img`
  width: 100%;
  border-radius: 2px;
`;

export const SociethySection: FC = () => {
  const { t } = useTranslation();

  return (
    <SociethySectionContainer>
      <SectionHeader
        title={t("manifesto.sectionHeaders.societhy.title")}
        description={t("manifesto.sectionHeaders.societhy.description")}
      />
      <div>
        <Image src={SociethyBanner} alt="" />
      </div>
    </SociethySectionContainer>
  );
};
