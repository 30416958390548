import React, { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { SectionHeader } from "components/molecules/sectionHeader";
import { ImageWithPreview } from "components/molecules/imageWithPreview";
import { Text } from "components/atoms/text";
import Times from "assets/images/manifesto/societhy-time-square.jpg";
import NYC from "assets/images/manifesto/nyc.jpg";
import LA from "assets/images/manifesto/la.jpg";
import Miami from "assets/images/manifesto/miami.jpg";
import Geneve from "assets/images/manifesto/geneve.jpg";
import SF from "assets/images/manifesto/sf.jpg";
import Paris from "assets/images/manifesto/paris.jpg";

const CommunitySectionContainer = styled.div`
  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    padding: 0 60px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    padding: 0 40px;
  }
`;

const SmallGrid = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 60px;
`;

const GridContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

// const VerticalSeparator = styled.div`
//   width: 1px;
//   background: #000;
// `;

export const CommunitySection: FC = () => {
  const { t } = useTranslation();

  return (
    <CommunitySectionContainer id="section">
      <SectionHeader
        title={t("manifesto.sectionHeaders.community.title")}
        description={t("manifesto.sectionHeaders.community.description")}
      />
      <GridContainer>
        <div>
          <Text fontSize="32" fontFamily="Post Grotesk" mb={20}>
            Born Global, Thriving Universal.
          </Text>
          <SmallGrid>
            <ImageWithPreview
              title="Paris"
              description="HQ"
              image={Paris}
              isPreview={false}
            />
            <ImageWithPreview
              title="NYC"
              description=""
              image={NYC}
              isPreview={false}
            />
            <ImageWithPreview
              title="San Francisco"
              description=""
              image={SF}
              isPreview={false}
            />
            <ImageWithPreview
              title="Miami"
              description=""
              image={Miami}
              isPreview={false}
            />
            <ImageWithPreview
              title="Geneve"
              description=""
              image={Geneve}
              isPreview={false}
            />
          </SmallGrid>
        </div>
        {/* <VerticalSeparator />
        <div>
          <Text fontSize="32" fontFamily="Post Grotesk" mb={20}>
            OG PASS
          </Text>
          <SmallGrid>
            <ImageWithPreview
              title="OG Pass"
              description="#16"
              image={Tinie1}
              isPreview
            />
            <ImageWithPreview
              title="OG Pass"
              description="#16"
              image={Tinie1}
              isPreview
            />
          </SmallGrid>
        </div> */}
      </GridContainer>
    </CommunitySectionContainer>
  );
};
