import React, { FC, useEffect } from "react";
import { useInView } from "react-intersection-observer";

type Props = {
  id: number;
  children: JSX.Element;
  idToNavigate: string;
  setActiveSection: (id: number) => void;
};

export const SectionContainer: FC<Props> = ({
  children,
  id,
  idToNavigate,
  setActiveSection,
}) => {
  const { ref, inView } = useInView({
    threshold: [1, 1, 1, 0.5],
  });

  useEffect(() => {
    if (inView) {
      setActiveSection(id);
    }
  }, [inView]);

  return (
    <div
      id={idToNavigate}
      style={{ marginBottom: "190px", scrollMarginTop: "80px" }}
      ref={ref}
    >
      <div>{children}</div>
    </div>
  );
};
